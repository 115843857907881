import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class PdfexportService {
  constructor() { }

  private chunkArray(myArray: any[], chunk_size: number): any[][] {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }



  downloadExcel(data: any[], fileName: string): void {
    const chunkSize = 100000; // chunk size for large data
    const chunks = this.chunkArray(data, chunkSize);

    // Create the workbook object
    const workbook: XLSX.WorkBook = {
      Sheets: {},
      SheetNames: [],
    };

    chunks.forEach((chunk, index) => {
      // Convert the data into a worksheet
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chunk, { header: Object.keys(chunk[0]) });
      console.log("consumer")
      // Get the range for header styling
      const range = XLSX.utils.decode_range(worksheet['!ref'] ?? '');
      debugger
      // Apply styles to the header row (only for the first chunk)
      if (index === 0) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const headerCell = worksheet[XLSX.utils.encode_cell({ r: range.s.r, c: col })];
          if (headerCell) {
            debugger
            headerCell.s = {
              fill: {
                fgColor: { rgb: 'A52A2A' }, // Brown color for header background
              },
              font: {
                bold: true, // Make text bold
                color: { rgb: 'FFFFFF' }, // White text
                sz: 12, // Font size (optional)
              },
              alignment: {
                horizontal: 'center', // Center the text horizontally
                vertical: 'center',   // Center the text vertically
              },
              border: { // Optional: add borders to header cells
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
            };
          }
        }
      }

      // Add the worksheet to the workbook
      workbook.Sheets[`Sheet${index + 1}`] = worksheet;
      workbook.SheetNames.push(`Sheet${index + 1}`);
    });

    // Generate the Excel buffer
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save the Excel file
    this.saveAsExcelFile(excelBuffer, fileName);
  }



  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}.xlsx`);
  }










}
