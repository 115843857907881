import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { Subscription, take } from 'rxjs';
import { getDueBillsResponse } from '../../../store/customer/consumer-selector';
import { getDueBills } from '../../../store/customer/consumer.action';
import { createinvildBills, getBillsByIdlist, Paycashbill, resetbillsState } from '../../../store/Bills/bills.action';
import { createinvildBillsResponse, getBillsByIdResponse } from '../../../store/Bills/bills-selector';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
@Component({
  selector: 'app-bills-view',
  templateUrl: './bills-view.component.html',
  styleUrl: './bills-view.component.scss'
})
export class BillsViewComponent {

  @Input() billsDataById: any;
  currentUser: any;
  projectId: string = '';
  billGenerationType: string = '';
  billList: any[] = [];
  paymentDetails: any;
  totalAmountwithoutpenalty: number = 0
  isAllOpen: boolean = true;
  breadCrumbItems: Array<{}> | undefined;
  private getBillsById$: Subscription = new Subscription;
  role: any
  billapidmodal!: BsModalRef;
  minDate: any;
  today: any;
  public paybycashform!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService,
    private modalService: BsModalService,
    private router: Router,
  ) {

    this.today = new Date();
    let lastMonthDate = new Date(this.today);
    lastMonthDate.setMonth(this.today.getMonth() - 1);
    console.log(lastMonthDate)
    console.log(this.billsDataById)
    this.minDate = lastMonthDate;
    this.paybycashform = this.fb.group({
      amount: [''],
      billPaymentDate: [null, Validators.required],
      reason: ['', Validators.required],
    })
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.billGenerationType = this.currentUser?.project.billGenerationType;
    this.role = this.currentUser?.currentUserRoleDetails.role;
    this.sharedService.billDetails$.subscribe((res: any) => {

      this.billsDataById = res;
      console.log(this.billsDataById)
    })
    if (Object.keys(this.billsDataById).length > 0) {
      this.store.dispatch(resetbillsState());
    }
    this.getbillsByid()
  }

  ngOnDestroy() {
    this.getBillsById$.unsubscribe();  // Or set it to [] if you prefer an empty array
  }

  ngOnInit() {
    this.loaderService.setLoading(true);
    //this.store.dispatch(getDueBills({ ProjectId: this.projectId }));
    this.store.dispatch(getBillsByIdlist({ id: this.billsDataById.id }));
  }
  /*  onCheckboxClick(event: Event, isChecked: boolean, index: number, billNo: string) {
     event.stopPropagation();
 
 
   } */
  ondownloadePdf(event: Event,) {
    event.stopPropagation();
    let temp = {
      id: this.billList[0].billNo,
    }
    //downloadBillsPayment

    this.sharedService.downloadBillsPayment(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = 'PaymentReceipt.pdf'; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });
  }

  reason: any;
  modalRefInvoice!: BsModalRef;
  /**
    * Open center modal
    * @param invalidBillModal center modal data
    */
  invalidBillsModal(invalidBillModal: any, event: Event) {
    event.stopPropagation();
    this.reason = ''
    this.modalRefInvoice = this.modalService.show(invalidBillModal, { class: 'modal-dialog-centered' });

  }

  saveInvlaidBills() {
    let request = {
      Reason: this.reason,
      Id: this.billsDataById.id,
      ResidetialUnitId: this.billList[0].residentialUnitId,
      ProjectId: this.projectId
    }
    this.store.dispatch(
      createinvildBills({ payload: request })
    );
    this.modalRefInvoice.hide();

  }


  isOtherBill: boolean = false
  ngAfterViewInit() {
    console.log(this.billsDataById)
  }
  getbillsByid() {
    let invalidBillreponce$ = this.store.pipe(select(createinvildBillsResponse));
    invalidBillreponce$.subscribe((res: any) => {
      if (res) {
        console.log(res)
        this.billList[0].isInvalidBill = res.data.isInvalidBill;
        console.log(this.billList)
        this.router.navigate(['/bills'],)
      }

    })

    this.getBillsById$ = this.store.pipe(select(getBillsByIdResponse)).subscribe((res: any) => {
      if (res) {
        let tempresponse: any[] = [];
        tempresponse.push(res)
        console.log(tempresponse)
        this.billList = tempresponse.map((bill: any, index: number) => {
          this.paymentDetails = {
            paymentDate: bill.paymentDate == '0001-01-01T00:00:00' ? null : bill.paymentDate,
            orderId: bill.orderId,
            paymentLink: bill.paymentLink,
            paymentStatus: bill.paymentStatus,
            paymentType: bill.paymentType,
            penaltyDate: bill.penaltyDate,
            settlementId: bill.settlementId,
            settlementDate: bill.settlementDate,
            modeOfPayment: bill.modeOfPayment,
            billDays: bill.billDays,
            lastBillGeneratedDate: bill.lastBillGeneratedDate,
            currentBillGeneratedDate: bill.currentBillGeneratedDate,
            paidAmount: bill.paidAmount,
            transactionId: bill.transactionId,
            billNo: bill.billNo,
            billGeneratedDate: bill.billGeneratedDate,
            totalAmount: bill.totalAmount,

          }
          // console.log(this.paymentDetails.billDays)
          // console.log(this.paymentDetails.lastBillGeneratedDate)
          //  console.log(this.paymentDetails.currentBillGeneratedDate)
          var gasbillAmount = 0;
          var waterbillAmount = 0;
          var elebillAmount = 0;
          var waterpenaltyAmount = 0;
          var elepenaltyAmount = 0;
          var gaspenaltyAmount = 0;

          bill.gas.forEach((gas: any, index: number) => {
            gasbillAmount = Number(gas.billAmount) || 0;
            gaspenaltyAmount = Number(gas?.penaltyAmount) || 0;
          })
          bill.water.forEach((water: any, index: number) => {
            waterbillAmount = Number(water?.billAmount) || 0;
            waterpenaltyAmount = Number(water?.penaltyAmount) || 0;
          })
          bill.electricity.forEach((ele: any, index: number) => {
            elebillAmount = Number(ele?.billAmount) || 0;
            elepenaltyAmount = Number(ele?.penaltyAmount) || 0;
          })

          this.totalAmountwithoutpenalty = 0;

          const totalGeneralCharge = Number(bill.totalGeneralCharge) || 0;

          if (this.billGenerationType == 'Combined') {
            this.totalAmountwithoutpenalty = totalGeneralCharge + gasbillAmount + waterbillAmount + elebillAmount;
          } else {
            this.totalAmountwithoutpenalty = 0;
            switch (bill?.utility) {

              case 'Electricity':
                this.totalAmountwithoutpenalty = this.totalAmountwithoutpenalty + elebillAmount + elepenaltyAmount;
                break;
              case 'Water':
                this.totalAmountwithoutpenalty = this.totalAmountwithoutpenalty + waterbillAmount + waterpenaltyAmount;
                break;
              case 'Gas':
                this.totalAmountwithoutpenalty = this.totalAmountwithoutpenalty + gasbillAmount + gaspenaltyAmount;
                break;
            }
          }
          return { ...bill, totalAmountwithoutpenalty: this.totalAmountwithoutpenalty };

        })
        this.loaderService.setLoading(false);
        console.log(this.billList)

      }
    })

  }
  markpiadAmount: any
  markASPaid3(markASPaid: any, totalAmount: any) {
    this.reason = '';
    this.markpiadAmount = totalAmount;
    console.log(this.markpiadAmount)
    this.paybycashform.controls['amount'].setValue(this.markpiadAmount);
    console.log(this.paybycashform.get('amount')?.value)
    this.billapidmodal = this.modalService.show(markASPaid, { class: 'modal-dialog-centered' });

  }
  payByCash() {
    console.log(this.paybycashform.invalid)
    if (this.paybycashform.invalid) {
      return;
    } else {
      let request = {
        "billId": this.billsDataById.id,
        "amount": this.paybycashform.get('amount')?.value,
        "paidDate": this.paybycashform.get('billPaymentDate')?.value,
        reason: this.paybycashform.get('reason')?.value
      }
      // console.log(request)
      this.store.dispatch(Paycashbill({ bilss: request }));
      this.billapidmodal.hide();
    }
  }
  buttondisabled: boolean = true
  billPaymentDateChange(event: any) {
    const dt = this.paybycashform.get('billPaymentDate')?.value;
    console.log(dt)
    console.log(event)
    if (dt) {
      this.buttondisabled = false;
    }
  }








}


