import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';

import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import {
  getBillHistoryExportResponse,
  getBillHistoryResponse,
} from '../../../store/customer/consumer-selector';
import {
  getBillHistory,
  getBillHistoryExport,
  resetBillHistoryState,
} from '../../../store/customer/consumer.action';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';
import { getBillsInvoice, getBillsInvoiceExport, resetbillsInvoiceExportState, resetbillsInvoiceState } from '../../../store/Bills/bills.action';
import { getBillsInvoiceExportResponse, getBillsInvoiceResponse } from '../../../store/Bills/bills-selector';
import { RpAlertsComponent } from '../rp-alerts/rp-alerts.component';
import { NotificationService } from '../../notification.service';
@Component({
  selector: 'app-rp-bill-invoices',
  templateUrl: './rp-bill-invoices.component.html',
  styleUrl: './rp-bill-invoices.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpBillInvoicesComponent {
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  invoiceNo: any
  paymentlaertModal!: BsModalRef;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  projectId: any;
  consumerId: any;
  currentUser: any;
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  public billInvoiceState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  billdate: any = [];
  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  exportData: any[] = [];
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public pageSize = 10;
  public skip = 0;
  billHistoryList: any[] = [];
  totalUserCount: number = 0;
  currentPage: number = 1;
  breadCrumbItems: Array<{}> | undefined;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  public billHistoryData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  private billsinvoicesSubscription: Subscription = new Subscription;

  billsInvoiceList: any[] = [];
  private invoiceGenerationUpdateSubscription: Subscription;
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private notificationService: NotificationService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );
    this.breadCrumbItems = [{ label: 'Invoice History' }];
    this.invoiceGenerationUpdateSubscription = this.notificationService.invoiceGenerationUpdate$.subscribe(data => {
      this.updateRechargePage(data);
    });




    this.store.dispatch(resetbillsInvoiceState());
    this.getbillHistoryStoreInfo();
  }
  role: any
  isFilter: any;
  IsConsumer: boolean = false

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.isFilter = localStorage.getItem('filter');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;
    this.projectId = this.currentUser?.project.id;
    this.role = this.currentUser?.currentUserRoleDetails.role;
    this.IsConsumer = this.role == 'Admin' ? false : true
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    if (this.isFilter == 'true') {
      this.filter = this.sharedService.getFilter();
      this.billInvoiceState.filter = this.filter;
      console.log(this.filter)
      console.log(this.billInvoiceState)
      this.queryString = this.sharedService.createQuery(this.billInvoiceState);
    }

    this.searchedValue = history.state.info ?? '';

    this.getInvoiceHistory();
  }

  updateRechargePage(data: any) {
    this.searchedValue = data;
    this.getInvoiceHistory();
    // Trigger any additional logic needed to refresh the page
  }
  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.billHistoryonPageChange({ skip: 0, take: this.pageSize })
      this.getInvoiceHistory()
    }
  }

  onSearchBillInvoice() {
    if (this.searchedValue.length > 0) {
      this.getInvoiceHistory()
    }
  }
  getInvoiceHistory() {
    this.store.dispatch(
      getBillsInvoice({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
        IsConsumer: this.IsConsumer
      })
    );

  }
  selsetedInvoicedetails: any
  /* openDownloadReceipt(data: any, paymentModel: any) {
    this.selsetedInvoicedetails = data

    this.paymentlaertModal = this.modalService.show(paymentModel, {
      class: 'modal-dialog-centered',
      backdrop: 'static',

    });
  } */

  closeAlert() {
    this.paymentlaertModal?.hide()
  }
  openDownloadReceipt3(data: any) {
    let paymentData = { ...data };
    paymentData['lastRechargeAmount'] = paymentData.totalAmount;
    paymentData['isInvoice'] = true
    paymentData['id'] = paymentData.invoiceNo;
    paymentData['name'] = paymentData.consumerName

    this.paymentlaertModal = this.modalService.show(RpAlertsComponent, {
      initialState: { paymentData },
      ...this.config,
    });
  }

  DownloadInvoice(data: any,) {
    let temp = {
      id: data.invoiceNo,
      isInvoice: true
    }

    this.sharedService.downloadPaymentReceipt(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = 'Inovice.pdf'; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });


  }



  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  private exportSubscription: Subscription = new Subscription();
  getbillHistoryStoreInfo() {
    this.store.dispatch(resetbillsInvoiceExportState());
    this.exportSubscription = this.store
      .pipe(select(getBillsInvoiceExportResponse))
      .subscribe((res: any) => {
        if (res) {
          console.log(res)
          this.exportData = [];
          if (res.items.length > 0) {
            this.exportData = [...res.items];
            const transformedData = this.exportData?.map((item: any) => ({
              'Invoice No': item.invoiceNo,
              'Residential Unit': item.residentialUnit,
              'consumer Name': item.consumerName,
              'Utility': item.utility,
              'Meter No': item.macAddress,
              'Total Amount': item.totalAmount,
              ' Invoice Date': item.currentInvoiceDate,
              'Mode of Payment': item.modeOfPayment,


            }));
            this.PdfexportService.downloadExcel(transformedData, 'billInvoice');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    this.billsinvoicesSubscription = this.store.pipe(select(getBillsInvoiceResponse)).subscribe({
      next: (res: any) => {
        if (res) {
          console.log(res)

          this.billHistoryList = [...res.items];

          console.log(this.billsInvoiceList)
          this.pageSize = res.pageSize;
          this.totalUserCount = res.totalCount;
          this.loadGridTable();


        }
      },
    })
  }
  ngOnDestroy() {
    if (this.invoiceGenerationUpdateSubscription) {
      this.invoiceGenerationUpdateSubscription.unsubscribe();
    }
    this.exportSubscription.unsubscribe();
    this.billsinvoicesSubscription.unsubscribe();
  }
  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.billHistoryList, state);
    this.billHistoryData = {
      data: this.billHistoryList,
      total: this.totalUserCount,
    };
    // this.loaderService.setLoading(false);
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.billInvoiceState.sort = sort;
    console.log(this.billInvoiceState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.billInvoiceState.sort
    );

    this.orderByQuery = orderByQuery;

    this.getInvoiceHistory()
  }

  public onBillInvoiceFilterChange(filter: CompositeFilterDescriptor): void {
    this.billInvoiceState.filter = filter;
    console.log(this.billInvoiceState);
    this.skip = 0
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);
    const queryString = this.sharedService.createQuery(this.billInvoiceState);
    this.queryString = this.sharedService.createQuery(this.billInvoiceState);
    this.currentPage = 1
    this.getInvoiceHistory()
    console.log(queryString);
  }
  filterBasedOnDate(flag: number) {
    if (flag == 0) {
      this.billdate = [];
      this.queryString = '';
    } else {
      if (this.billdate.length === 2) {
        const startDate = this.billdate[0];
        const endDate = this.billdate[1];

        if (startDate && endDate) {
          // Format the dates to yyyy-MM-dd
          const formattedStartDate = startDate.toISOString().split('T')[0];
          const formattedEndDate = endDate.toISOString().split('T')[0];

          // Construct the filter query with only the date
          this.queryString = `$filter=(currentInvoiceDate ge ${formattedStartDate} and currentInvoiceDate le ${formattedEndDate})`;
        } else {
          console.log('Invalid date range');
        }
      } else {
        console.log('Invalid date selection');
      }
    }

    this.getInvoiceHistory();
  }

  selectDates(event: any) {
    //console.log(event);
    if (event == undefined) {
      this.billdate = [];
      this.queryString = '';
      this.getInvoiceHistory()
    }
  }


  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  clearDate() {
    this.billdate = []; // Clear the selected date
  }


  protected billHistoryonPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getInvoiceHistory()


    this.loadGridTable();
  }

  protected billHostoryStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  public onFilter(value: Event): void {
    console.log(this.searchedValue);
    // const inputValue = value;
    // this.searchedValue = value;
    this.billHistoryonPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getBillsInvoice({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        IsConsumer: this.IsConsumer,
        ProjectId: this.projectId,
      })
    );
  }
  exportAll() {
    this.store.dispatch(
      getBillsInvoiceExport({ ProjectId: this.projectId, criteria: 'All', IsConsumer: this.IsConsumer, })
    );
  }
  onExport(type: string) {
    /* if (this.selectedRowdata.length == 0) {
      this.store.dispatch(getBillHistoryExport({ ProjectId: this.projectId, criteria: 'All', }));
    } */
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  onViewClick(item: any) {
    this.router.navigate(['/billInvoices/view-invoice'], {
      state: { billHostorydeatils: item },
    });
  }
}
